import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

export class SetupMfaStateHandler {
  loadMfaQrCode(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.SetupMfa;
    this.vlaidateNewPasswordRepeat();
    // UserApi
    this.authApi
      .apiGrexmanagerUserMfaSetupCreate()
      .then((response: any) => {
        this.state.setupMfa.setup_key = response.base32;
        this.state.setupMfa.otpauth_url = response.otpauth_url;
        callback(this.state);
      })
      .catch((error) => {
        console.log(error);

        this.state.setupMfa.errorMessage =
          "Fout bij het laden van Multi-Factor Authenticatie (MFA).";
        callback(this.state);
      });
  }

  VerifyMfaCode(
    this: AppStateHandler,
    mfa_code: string,
    callback: (newState: AppStateType) => void
  ) {
    this.state.currentUser.mfaInput = mfa_code;
    callback(this.state);
    if (mfa_code.length === 6) {
      this.authApi
        .apiGrexmanagerUserMfaVerifyCreate({
          twoFactorAuthVerify: {
            mfaCode: mfa_code,
            userEmail: this.state.currentUser.userEmail,
          },
        })
        .then((response: any) => {
          if (response.mfa_verified === true) {
            this.state.currentUser.mfaInput = "";
            callback(this.state);
            this.userApi
              .apiGrexmanagerUserMeRetrieve()
              .then((response) => {
                this.state.currentUser.isSuperAdmin = response.isStaff || false;
                this.state.currentUser.userEmail = response.email || "";
                this.state.currentUser.mfaVerified =
                  response.mfaVerified || false;
                this.state.currentUser.mfaForced = response.mfaForced || false;
                this.state.loggedIn = true;
                this.changeScreen(ScreenState.Projects, callback);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.state.currentUser.mfaInput = "";
            this.state.setupMfa.errorMessage =
              "De ingevoerde code is ongeldig.";
            callback(this.state);
          }
        })
        .catch((error: any) => {
          this.state.currentUser.mfaInput = "";
          this.state.setupMfa.errorMessage = "De ingevoerde code is ongeldig.";
          callback(this.state);
        });
    } else {
      this.state.setupMfa.errorMessage = "";
      callback(this.state);
    }
  }
}
