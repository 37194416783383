import {
  ApiGrexmanagerUserNewCreateRequest,
  ResponseError,
  RoleEnum,
} from "@shared/client/lib";
import { isEmailValid } from "@shared/utils/helperFunctions";
import { StateEnum } from "../../../../shared/client/lib";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";

import { checkPasswordPolicy } from "../../helperFunctions";
export class CreateUserStateHandler {
  loadCreateUser(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.state.NewUser = {
      firstName: "",
      lastName: "",
      email: "",
      role: RoleEnum.Non,
      password: "",
      passwordRepeat: "",
      NewUserError: "",
      subregions: [],
      subregions_message: "",
      templateReports: [],
      templateReports_message: "",
      projectsRoles: [],
      projectsRoles_message: "",
      allProjectRolesDropdown: null,
    };
    this.state.screenState = ScreenState.CreateUser;

    this.CreateUserLoadProjects(callback);
    this.CreateUserLoadSubregions(callback);
    this.CreateUserLoadTemplateReports(callback);
    callback(this.state);
  }
  CreateUserForm(
    this: AppStateHandler,
    newuser: {
      firstName?: string;
      lastName?: string;
      email?: string;
      role?: RoleEnum;
      password?: string;
      passwordRepeat?: string;
      NewUserError?: string;
    },
    callback: (newState: AppStateType) => void
  ) {
    this.state.screenState = ScreenState.CreateUser;
    this.state.NewUser.firstName =
      newuser.firstName || this.state.NewUser.firstName;
    this.state.NewUser.lastName =
      newuser.lastName || this.state.NewUser.lastName;
    this.state.NewUser.email = newuser.email || this.state.NewUser.email;
    this.state.NewUser.role = newuser.role || this.state.NewUser.role;
    this.state.NewUser.password =
      newuser.password || this.state.NewUser.password;
    if (this.state.NewUser.password !== "") {
      checkPasswordPolicy(
        this.state.PasswordPolicy.newPasswordRequirements,
        this.state.NewUser.password,
        this.state.PasswordPolicy.notAppliedRequirements
      );
    }
    this.state.NewUser.passwordRepeat =
      newuser.passwordRepeat || this.state.NewUser.passwordRepeat;
    this.state.NewUser.NewUserError =
      newuser.NewUserError || this.state.NewUser.NewUserError;
    if (
      this.state.NewUser.password !== this.state.NewUser.passwordRepeat &&
      this.state.NewUser.passwordRepeat !== ""
    ) {
      this.state.NewUser.NewUserError = "Wachtwoorden komen niet overeen";
    } else {
      this.state.NewUser.NewUserError = "";
    }
    callback(this.state);
  }
  validateCreateUser(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    if (
      this.state.NewUser.firstName === "" ||
      this.state.NewUser.lastName === "" ||
      this.state.NewUser.email === "" ||
      this.state.NewUser.password === "" ||
      this.state.NewUser.passwordRepeat === ""
    ) {
      this.state.NewUser.NewUserError =
        "Niet alle vereiste velden zijn ingevuld.";
      callback(this.state);
      return false;
    } else if (isEmailValid(this.state.NewUser.email) === false) {
      this.state.NewUser.NewUserError = "Ongeldig e-mailadres.";
      callback(this.state);
      return false;
    } else if (
      this.state.NewUser.password !== this.state.NewUser.passwordRepeat
    ) {
      this.state.NewUser.NewUserError = "Wachtwoorden komen niet overeen";
      callback(this.state);
      return false;
    } else {
      this.state.NewUser.NewUserError = "";
      callback(this.state);
      return true;
    }
  }

  CreateUserLoadSubregions(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.subregionApi
      .apiGrexmanagerSubregionList()
      .then((subregions) => {
        subregions.map((subregion) => {
          this.state.NewUser.subregions.push({
            subregionId: subregion.id,
            subregionDescription: subregion.description,
            access: false,
            user: 0,
          });
          callback(this.state);
          return subregion;
        });
      })
      .catch((error) => {
        console.log(error);
        this.state.NewUser.NewUserError =
          "Deelgebieden konden niet geladen worden";
        callback(this.state);
      });
  }
  CreateUserLoadTemplateReports(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.templateReportApi
      .apiGrexmanagerTemplateReportList({
        filter: [StateEnum.ActAuth],
      })
      .then((templateReports) => {
        templateReports.map((templateReport) => {
          this.state.NewUser.templateReports.push({
            templateReportId: templateReport.id,
            templateReportName: templateReport.name,
            access: false,
            user: 0,
          });
          callback(this.state);
          return templateReport;
        });
      })
      .catch((error) => {
        console.log(error);
        this.state.NewUser.NewUserError =
          "Rapportage sjablonen konden niet geladen worden";
        callback(this.state);
      });
  }
  CreateUserLoadProjects(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    this.projectApi
      .apiGrexmanagerProjectList({
        all: true,
      })
      .then((projects) => {
        projects.map((project) => {
          this.state.NewUser.projectsRoles.push({
            projectId: project.id,
            projectName: project.name,
            role: RoleEnum.Non,
            user: 0,
          });
          callback(this.state);
          return project;
        });
      })
      .catch((error) => {
        console.log(error);
        this.state.NewUser.NewUserError =
          "Projecten konden niet geladen worden";
        callback(this.state);
      });
  }

  CreateUserSubregionAccessUpdateForm(
    this: AppStateHandler,
    subregionId: number,
    access: boolean,
    callback: (newState: AppStateType) => void
  ) {
    this.state.NewUser.subregions.map((subregion) => {
      if (subregion.subregionId === subregionId) {
        subregion.access = access;
      }
      return subregion;
    });
    callback(this.state);
  }
  CreateUserTemplateReportAccessUpdateForm(
    this: AppStateHandler,
    templateReportId: number,
    access: boolean,
    callback: (newState: AppStateType) => void
  ) {
    this.state.NewUser.templateReports.map((templateReport) => {
      if (templateReport.templateReportId === templateReportId) {
        templateReport.access = access;
      }
      return templateReport;
    });
    callback(this.state);
  }

  CreateUserProjectRoleUpdateForm(
    this: AppStateHandler,
    projectId: number,
    role: RoleEnum,
    callback: (newState: AppStateType) => void
  ) {
    this.state.NewUser.projectsRoles.map((project) => {
      if (project.projectId === projectId) {
        project.role = role;
        this.state.NewUser.allProjectRolesDropdown = null;
      }
      return project;
    });
    callback(this.state);
  }

  async CreateUserSubregions(
    this: AppStateHandler,
    user_id: number
  ): Promise<boolean> {
    try {
      await this.userAdminApi.apiGrexmanagerUserSubregionCreate({
        id: user_id,
        grexUserSubregionListResponse: this.state.NewUser.subregions,
      });
      return true; // return true when successful
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async CreateUserTemplateReports(
    this: AppStateHandler,
    user_id: number
  ): Promise<boolean> {
    try {
      await this.userAdminApi.apiGrexmanagerUserTemplateReportCreate({
        id: user_id,
        grexUserTemplateReportListResponse: this.state.NewUser.templateReports,
      });
      return true; // return true when successful
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async CreateUserProjectsRoles(
    this: AppStateHandler,
    user_id: number
  ): Promise<boolean> {
    try {
      await this.userAdminApi.apiGrexmanagerUserProjectRoleCreate({
        id: user_id,
        grexUserProjectRoleListResponse: this.state.NewUser.projectsRoles,
      });
      return true; // return true when successful
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  CreateUserAllProjectsRoles(
    this: AppStateHandler,
    role: RoleEnum,
    callback: (newState: AppStateType) => void
  ) {
    this.state.NewUser.allProjectRolesDropdown = role;
    this.state.NewUser.projectsRoles.forEach((projectRole) => {
      projectRole.role = role;
    });
    callback(this.state);
  }

  async CreateUser(
    this: AppStateHandler,
    callback: (newState: AppStateType) => void
  ) {
    let is_valid = this.validateCreateUser(callback);
    if (is_valid) {
      if (this.state.PasswordPolicy.notAppliedRequirements.length > 0) {
        this.state.NewUser.NewUserError =
          "Wachtwoord voldoet niet aan de eisen";
        callback(this.state);
        return;
      }
      const newUserRequest: ApiGrexmanagerUserNewCreateRequest = {
        grexUserCreate: {
          firstName: this.state.NewUser.firstName,
          lastName: this.state.NewUser.lastName,
          email: this.state.NewUser.email,
          role: this.state.NewUser.role,
          password: this.state.NewUser.password,
        },
      };

      // Create the user
      this.userAdminApi
        .apiGrexmanagerUserNewCreate(newUserRequest)
        .then((createduser) => {
          const createduser_id = createduser.id;

          // Give the user access to the selected subregions, template reports and projects
          Promise.all([
            this.CreateUserSubregions(createduser_id),
            this.CreateUserTemplateReports(createduser_id),
            this.CreateUserProjectsRoles(createduser_id),
          ]).then((results) => {
            const errorIndex = results.indexOf(false);
            if (errorIndex > -1) {
              if (errorIndex === 0) {
                // user created but cannot assign subregions
                this.state.UserListScreen.userlist_message =
                  "Gebruiker aangemaakt. Deelgebieden toekennen mislukt";
                callback(this.state);
                setTimeout(() => {
                  this.state.UserListScreen.userlist_message = "";
                  callback(this.state);
                }, 5000);
              } else if (errorIndex === 1) {
                // user created but cannot assign template reports
                this.state.UserListScreen.userlist_message =
                  "Gebruiker aangemaakt. Rapportage sjablonen toekennen mislukt";
                callback(this.state);
                setTimeout(() => {
                  this.state.UserListScreen.userlist_message = "";
                  callback(this.state);
                }, 5000);
              } else if (errorIndex === 2) {
                // user created but cannot assign projects
                this.state.UserListScreen.userlist_message =
                  "Gebruiker aangemaakt. Projecten toekennen mislukt";
                callback(this.state);
                setTimeout(() => {
                  this.state.UserListScreen.userlist_message = "";
                  callback(this.state);
                }, 5000);
              }
            } else {
              // Notify the user that it was successful
              this.state.NewUser.NewUserError = "";
              this.state.UserListScreen.userlist_message = `${createduser.firstName} ${createduser.lastName} is aangemaakt!`;
              callback(this.state);
              setTimeout(() => {
                this.state.UserListScreen.userlist_message = "";
                callback(this.state);
              }, 5000);
            }

            // Change the screen
            this.changeScreen(ScreenState.UserList, callback);
            callback(this.state);
          });
        })
        .catch((error) => {
          // error while creating user
          if (
            error instanceof ResponseError &&
            (error as ResponseError).response.status === 409
          ) {
            this.state.NewUser.NewUserError =
              "E-mail is al in gebruik door een andere gebruiker.";
            callback(this.state);
            setTimeout(() => {
              this.state.NewUser.NewUserError = "";
              callback(this.state);
            }, 5000);
          } else {
            this.state.NewUser.NewUserError =
              "Er ging iets mis bij het aanmaken van een gebruiker.";
            callback(this.state);
            setTimeout(() => {
              this.state.NewUser.NewUserError = "";
              callback(this.state);
            }, 5000);
          }
        });
    }
  }
}
